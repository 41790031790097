<template>
  <div class="prms">
    <div class="v-head">
      <div class="v-row">
        <div class="v-col-1">
          <b-form-checkbox
            v-model="value.selectedAll"
            name="item-selected-all"
            @change="changeAll()"
          />
        </div>
        <div class="v-col-2">
          <span class="v-file-name">Tên sản phẩm</span>
        </div>
        <div class="v-col-3">
          <span class="v-file-name">Giá gốc</span>
        </div>
        <div class="v-col-4">
          <span class="v-file-name">Giá sau giảm</span>
        </div>
        <div class="v-col-5">
          <span class="v-file-name">Giảm giá</span>
        </div>
        <div class="v-col-6">
          <span class="v-file-name">Kho hàng</span>
        </div>
        <div class="v-col-7">
          <span class="v-file-name">Số lượng</span>
        </div>
        <div class="v-col-8">
          <span class="v-file-name">Giới hạn đặt</span>
        </div>
        <div class="v-col-9">
          <span class="v-file-name">Bật / Tắt</span>
        </div>
        <div class="v-col-10">
          <span class="v-file-name">Thao tác</span>
        </div>
      </div>
    </div>
    <div
      v-for="item, index in value.list_product"
      :key="index"
      class="v-item"
    >
      <div class="v-row v-item-head">
        <div class="v-col-1">
          <span class="v-check">
            <b-form-checkbox
              v-model="value.selected"
              :value="item.id"
              :name="`item-${index}`"
            />
          </span>
        </div>
        <div class="v-col-2-4">
          <img
            class="v-avatar"
            :src="item.avatar"
            alt=""
          >
          <span class="v-name-prod">{{ item.name }}</span>
        </div>
        <div class="v-col-5">
          &#160;
        </div>
        <div class="v-col-6">
          &#160;
        </div>
        <div class="v-col-7">
          &#160;
        </div>
        <div class="v-col-8">
          <div
            class="v-limit-grpup"
            :class="value.list_product[index].isLimitProduct"
          >
            <select
              v-model="value.list_product[index].isLimitProduct"
              class="v-limit-grpup-item"
            >
              <option
                v-for="itemsl, indexsl in limitProductsOptions"
                :key="indexsl"
                :value="itemsl.value"
              >{{ itemsl.text }}</option>
            </select>
            <input
              v-model="value.list_product[index].limitProducts"
              class="v-limit-grpup-item"
              type="number"
            >
          </div>
        </div>
        <div class="v-col-9">
          &#160;
        </div>
        <div class="v-col-10">
          <span
            class="v-btn-del"
            @click="removeItem(index)"
          ><feather-icon icon="Trash2Icon" /></span>
        </div>
      </div>
      <div
        v-for="itemChild, indexChild in item.children"
        :key="indexChild"
        class="v-row"
      >
        <div class="v-col-1">
          &#160;
        </div>
        <div class="v-col-2">
          <span class="v-file-name">{{ itemChild.name }}</span>
        </div>
        <div class="v-col-3">
          <span class="v-file-name">{{ itemChild.price }}</span>
        </div>
        <div class="v-col-4-5">
          <div class="v-block-price">
            <div class="v-input-group v-item-discount">
              <span class="v-input-label">₫</span>
              <input
                v-model="value.list_product[index].children[indexChild].discount"
                type="number"
                :class="`v-input ${itemChild.valid.ok_discount}`"
                @input="mathPrice(index, indexChild, 'discount')"
              >
            </div>
            <span class="v-mid-text">Hoặc</span>
            <div class="v-input-group v-item-percent">
              <span class="v-input-label">%GIẢM</span>
              <input
                v-model="value.list_product[index].children[indexChild].percent"
                type="number"
                :class="`v-input ${itemChild.valid.ok_percent}`"
                @input="mathPrice(index, indexChild, 'percent')"
              >
            </div>
          </div>
        </div>
        <div class="v-col-6">
          <span
            class="v-file-name"
            :class="itemChild.valid.ok_inventory"
          >{{ itemChild.inventory }}</span>
        </div>
        <div class="v-col-7">
          <div
            :class="`v-limit-grpup ${value.list_product[index].children[indexChild].is_limit_warehouse}`"
          >
            <select
              v-model="value.list_product[index].children[indexChild].is_limit_warehouse"
              class="v-limit-grpup-item"
              @change="validCheck(index, indexChild)"
            >
              <option
                v-for="itemsl, indexsl in limitProductsOptions"
                :key="indexsl"
                :value="itemsl.value"
              >{{ itemsl.text }}</option>
            </select>
            <input
              v-model="value.list_product[index].children[indexChild].limit_warehouse"
              :class="`v-limit-grpup-item ${itemChild.valid.ok_limit_warehouse}`"
              type="number"
              @input="validCheck(index, indexChild)"
            >
          </div>
        </div>
        <div class="v-col-8">
          <span class="v-file-name">
            &#160;
          </span>
        </div>
        <div class="v-col-9">
          <span class="v-file-name"><b-form-checkbox
            v-model="value.list_product[index].children[indexChild].status"
            class="custom-control-secondary"
            :name="`item-${index}-${indexChild}`"
            :disabled="itemChild.valid.status == false || itemChild.valid.warning == true"
            switch
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox></span>
        </div>
        <div class="v-col-10">
          <span
            v-if="itemChild.valid.status == false"
            class="v-valid-status"
          >
            <feather-icon icon="AlertTriangleIcon" />
          </span>

          <span
            v-if="itemChild.valid.warning == true && itemChild.valid.status == true"
            class="v-valid-warning"
          >
            <feather-icon icon="AlertTriangleIcon" />
          </span>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BFormCheckbox,
} from 'bootstrap-vue'

export default {
  components: {
    BFormCheckbox,
  },
  props: {
    value: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      limitProductsOptions: [
        {
          value: null,
          text: 'Không giới hạn',
        },
        {
          value: 'limit',
          text: 'Giới hạn',
        },
      ],
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    'value.validCheckAll': function () {
      this.validCheckAll()
    },
  },
  methods: {
    mathPrice(index, indexChild, root) {
      const temp = this.value.list_product[index].children[indexChild]
      if (root === 'percent') {
        temp.discount = temp.price - ((temp.price / 100) * temp.percent)
      } else {
        temp.percent = (100 - (((temp.discount * 100) / temp.price))).toFixed(2)
      }
      this.validCheck(index, indexChild)
    },
    changeAll() {
      if (this.value.selectedAll === true) {
        this.value.list_product.map(val => {
          if (!this.value.selected.includes(val.id)) {
            this.value.selected.push(val.id)
          }
          return true
        })
      } else {
        this.value.selected = []
      }
    },
    removeItem(index) {
      this.value.list_product.splice(index, 1)
    },
    validCheckAll() {
      this.value.list_product.map((val, index) => {
        const findData = this.value.list_product[index]
        findData.children.map((val2, index2) => {
          this.validCheck(index, index2)
          return true
        })
        return true
      })
    },
    validCheck(index, indexChild) {
      const temp = this.value.list_product[index].children[indexChild]
      if (Number(temp.percent) === 0 || temp.percent > 100 || temp.percent < 0) {
        temp.valid.ok_percent = 'error'
      } else {
        temp.valid.ok_percent = ''
      }

      if (temp.inventory < Number(temp.limit_warehouse) && temp.is_limit_warehouse === 'limit') {
        temp.valid.ok_limit_warehouse = 'error'
      } else {
        temp.valid.ok_limit_warehouse = ''
      }
      if (temp.discount > temp.price || temp.discount < 0) {
        temp.valid.ok_discount = 'error'
      } else {
        temp.valid.ok_discount = ''
      }

      let numEr = 0
      let numWr = 0
      Object.keys(temp.valid).map(isK => {
        if (temp.valid[isK] === 'error') {
          numEr += 1
        }
        if (temp.valid[isK] === 'warning') {
          numWr += 1
        }
        return true
      })
      if (numEr === 0) {
        temp.valid.show = false
        temp.valid.status = true
        temp.status = true
      } else {
        temp.valid.show = true
        temp.valid.status = false
        temp.status = false
      }

      if (numWr === 0) {
        temp.valid.show = false
        temp.valid.warning = false
        if (numEr === 0) {
          temp.status = true
        }
      } else {
        temp.valid.show = true
        temp.valid.warning = true
        temp.status = false
      }
    },
  },
}
</script>

<style lang="scss">
  .prms{
    position: relative;
    input, select{
      height: 26px;
      border: 1px solid #ddd;
    }
    input:focus,
    input:focus-visible,
    input:active,
    select:focus,
    select:active{
      border: 1px solid #ddd;
      outline: none;
    }
    .v-row{
      display: flex;
      .v-col-1{
        width: 3%;
      }
      .v-col-2{
        width: 12%;
      }
      .v-col-3{
        width: 10%;
      }
      .v-col-4{
        width: 13%;
      }
      .v-col-5{
        width: 13%;
      }
      .v-col-6{
        width: 7%;
      }
      .v-col-7{
        width: 15%;
      }
      .v-col-8{
        width: 13%;
      }
      .v-col-9{
        width: 7%;
      }
      .v-col-10{
        width: 7%;
      }
      .v-col-2-4{
        width: 38%;
        display: flex;
        align-items: center;
      }
      .v-col-4-5{
        width: 26%;
        padding-right: 10px;
      }
    }
    .v-file-name{
      display: block;
    }
    .v-head{
      background: #eee;
      padding: 10px;
      position: sticky;
      top: 62px;
      z-index: 12;
    }
    .v-item{
      margin-top: 10px;
      border: 1px solid #ddd;

      .v-item-head{
        padding: 5px 10px;
        background: #ddd;
        position: relative;
        align-items: center;
        display: flex;
      }
      .v-row{
        padding: 10px;
      }
    }
    .v-avatar{
      width: 35px;
      height: 35px;
      object-fit: cover;
      margin-right: 10px;
    }
    .v-name-prod{
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
    }
    .v-block-price{
      display:flex;
      align-items: center;
    }
    .v-input-group{
      max-width: 39%;
      .v-input{
        width: 100%;
      }
    }
    .v-mid-text{
      font-size: 11px;
      font-style: italic;
      margin-left: 5px;
      margin-right: 5px;
    }

    .v-item-discount{
      position: relative;
      .v-input-label{
        position: absolute;
        left: 7px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 11px;
      }
      .v-input{
        padding-left: 20px;
      }
    }

    .v-item-percent{
      position: relative;
      .v-input-label{
        position: absolute;
        right: 7px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 11px;
      }
      .v-input{
        padding-right: 50px;
      }
    }
    .v-limit-grpup{
      display: flex;
      align-items: center;
      padding-right: 5px;
      .v-limit-grpup-item{
        max-width: 100%;
      }
      select{
        font-size: 11px;
      }
      input{
        display: none;
        margin-left: -2px;
      }
      &.limit{
        select{
          max-width: 70px;
        }
        input{
          display: block;
          max-width: calc(100% - 90px);
        }
      }
    }
    .v-btn-del{
      cursor: pointer;
      width: 30px;
      height: 30px;
      display: flex;
      margin: 0 auto;
      align-content: center;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background: #fff;
      font-size: 12px;
      &:hover{
        background: tomato;
        color: #fff;
      }
    }
    .v-valid-status{
      cursor: pointer;
      width: 25px;
      height: 25px;
      display: flex;
      margin: 0 auto;
      align-content: center;
      justify-content: center;
      align-items: center;
      color: red;
    }
    .v-valid-warning{
      cursor: pointer;
      width: 25px;
      height: 25px;
      display: flex;
      margin: 0 auto;
      align-content: center;
      justify-content: center;
      align-items: center;
      color: #ff9f43;
    }
    .error{
      color: red;
    }
    .warning{
      color: #ff9f43;
    }
  }
</style>
